<template>
    <v-main>
        <v-row justify="space-around">
            <v-card 
                class="mx-auto my-12"
            >
                <v-card-title>Página contas em construção!</v-card-title>
            
            </v-card>
        </v-row>
    </v-main>
</template>
<script>
export default {
    name:'Pessoas',
}
</script>